<template>
  <div class="container">
    <div class="p_f ali_c j_s d_f top_head">
      <img src="../assets/img/jf_back.png" alt="" class="cur_p back" @click="$router.go(-1)">
      <!--<img src="../assets/img/jf_share.png" alt="" class="cur_p share">-->
    </div>
    <img class="full_w" src="../assets/img/jf_bg.png" alt="">
  </div>
</template>

<script>
  export default {
    name: "jfOutWindow",
    data() {
      return {}
    },
    created(){
    },
    methods: {
      revert(){

      }
    }
  }
</script>

<style scoped lang="scss">
  .container{
    .top_head{
      width: 100%;
      top: 0;
      left: 0;
      padding: 15px;
      box-sizing: border-box;
      .back{
        width: 12px;
        height: 20px;
      }
      .share{
        width: 20px;
        height: 20px;
      }
    }
    width: 100%;
    height: 100vh;
  }

</style>
